import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store, persistor } from "redux/store";
import { loadDefaultVendor } from "actions";

const Home = lazy(() => import("./components/Home"));
const User = lazy(() => import("./components/User"));
const Apply = lazy(() => import("./components/Apply"));
const Vendor = lazy(() => import("./components/Vendor"));
const Statements = lazy(() => import("./components/Statements"));
const EvenFinDirect = lazy(() => import("./components/EvenFinDirect"));
const Preload = lazy(() => import("./components/Preload"));

import ErrorBoundary from "./components/_shared/Error/Error";
import TagManager from "react-gtm-module";

Sentry.init({
	dsn: SENTRY_DSN,
	environment: SENTRY_ENV,
	integrations: [new BrowserTracing(), new Sentry.Replay()],
	tracesSampleRate: 0.5,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

TagManager.initialize({
	gtmId: GOOGLE_TAG_MANAGER_ID,
});


const App = () => {
	const loadVendorRoute = "/go/:vendor/:id?";
	const userRouteDefault = "/user/:action";
	const appRouteDefault = "/app/:step/:property?";
	const appRouteIndexed = "/app/:step/:idx/:property";
	const evenRouteLoader = "/personal-loan/:id";
	const appRouteLoader = "/load/:id";
	const stmtRouteLoader = "/share-statements/:id?";

	// load default config
	store.dispatch(loadDefaultVendor());

	return (
		<Router>
			<ErrorBoundary>
				<Switch>
					<Suspense fallback={<div>Loading...</div>}>
						<Route exact path="/" component={Home} />
						<Route exact path={loadVendorRoute} component={Vendor} />
						<Route exact path={userRouteDefault} component={User} />
						<Route exact path={appRouteDefault} component={Apply} />
						<Route exact path={appRouteIndexed} component={Apply} />
						<Route exact path={evenRouteLoader} component={EvenFinDirect} />
						<Route exact path={appRouteLoader} component={Preload} />
						<Route exact path={stmtRouteLoader} component={Statements} />
					</Suspense>
				</Switch>
			</ErrorBoundary>
		</Router>
	);
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);
