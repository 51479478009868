import { combineReducers } from "redux";
import {
	SET_THEME,
	CLEAR_THEME,
	SET_CONFIG,
	SET_TEMP,
	CLEAR_TEMP,
	SET_DATA,
	CLEAR_DATA,
	LOAD_VENDOR_STARTED,
	LOAD_VENDOR_SUCCESS,
	LOAD_VENDOR_FAILURE,
	LOAD_VENDOR_DEFAULT,
	SET_USER,
	AUTH_FAILED,
	LOGOUT,
	REFRESH_USER_STARTED,
	REFRESH_USER_SUCCESS,
	REFRESH_USER_FAILURE,
} from "./actions";
import { reducer as formReducer } from "redux-form";

function themeReducer(state = {}, action) {
	switch (action.type) {
		case SET_THEME:
			return action.theme;
		case CLEAR_THEME:
			return {};
		default:
			return state;
	}
}

const initialState = {
	loaded: false,
	error: null,
	customVendor: false,
};

function configReducer(state = initialState, action) {
	switch (action.type) {
		case SET_CONFIG:
			return action.config;

		case LOAD_VENDOR_STARTED:
			return {
				loaded: false,
				customVendor: true,
			};
		case LOAD_VENDOR_SUCCESS:
			return {
				loaded: true,
				error: null,
				customVendor: true,
				...action.payload,
			};
		case LOAD_VENDOR_FAILURE:
			return {
				loaded: false,
				error: action.payload.error,
			};

		case LOAD_VENDOR_DEFAULT:
			return {
				loaded: true,
				error: null,
				customVendor: false,
				...action.payload,
			};

		default:
			return state;
	}
}

function userReducer(state = {}, action) {
	switch (action.type) {
		case SET_USER:
			return action.user;

		case AUTH_FAILED:
			return {};

		case LOGOUT:
			return {};

		case REFRESH_USER_STARTED:
			return {
				refreshed: false,
				...action.payload,
			};
		case REFRESH_USER_SUCCESS:
			return {
				refreshed: true,
				error: null,
				...action.payload,
			};
		case REFRESH_USER_FAILURE:
			return {
				refreshed: false,
				error: action.payload.error,
			};

		default:
			return state;
	}
}

function tempReducer(state = {}, action) {
	switch (action.type) {
		case SET_TEMP:
			// unset error and msg properties
			const temp = Object.assign({}, action.data, {
				error: undefined,
				msg: undefined,
			});
			// append new response temp
			return { ...state, ...temp };

		case CLEAR_TEMP:
			// reset temp data
			return {};

		default:
			return state;
	}
}

function dataReducer(state = {}, action) {
	switch (action.type) {
		case SET_DATA:
			return {
				deals: action.deals,
			};

		case CLEAR_DATA:
			return {};

		default:
			return state;
	}
}

export default combineReducers({
	form: formReducer,
	theme: themeReducer,
	config: configReducer,
	temp: tempReducer,
	data: dataReducer,
	user: userReducer,
});
