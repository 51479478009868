import axios from "axios";
import { change } from "redux-form";
import styles from "scss/styles.scss";

export const SET_THEME = "SET_THEME";
export const CLEAR_THEME = "CLEAR_THEME";
export const SET_TEMP = "SET_TEMP";
export const CLEAR_TEMP = "CLEAR_TEMP";
export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";

export const SET_CONFIG = "SET_CONFIG";
export const LOAD_VENDOR_STARTED = "LOAD_VENDOR_STARTED";
export const LOAD_VENDOR_SUCCESS = "LOAD_VENDOR_SUCCESS";
export const LOAD_VENDOR_FAILURE = "LOAD_VENDOR_FAILURE";
export const LOAD_VENDOR_DEFAULT = "LOAD_VENDOR_DEFAULT";

export const SET_USER = "SET_USER";
export const AUTH_FAILED = "AUTH_FAILED";
export const LOGOUT = "LOGOUT";
export const REFRESH = "REFRESH";
export const REFRESH_USER_STARTED = "REFRESH_USER_STARTED";
export const REFRESH_USER_SUCCESS = "REFRESH_USER_SUCCESS";
export const REFRESH_USER_FAILURE = "REFRESH_USER_FAILURE";

export function setTheme(theme) {
	return {
		type: SET_THEME,
		theme: theme,
	};
}

const setCustomTheme = (config, dispatch) => {
	const themeList = {
		default: {
			primary: styles.primary,
			primaryRgba: styles.primaryRgba,
			secondary: styles.secondary,
			name: "default",
		},
		lendingTree: {
			primary: styles.lendingTreePrimary,
			primaryRgba: styles.lendingTreeRgba,
			secondary: styles.secondary,
			name: "lendingtree",
		},
		kwipped: {
			primary: styles.kwippedPrimary,
			primaryRgba: styles.kwippedRgba,
			secondary: styles.secondary,
			name: "kwipped",
		},
	};

	let theme;

	switch (config.name) {
		case "Lending Tree":
			theme = themeList.lendingTree;
			break;
		case "Kwipped":
			theme = themeList.kwipped;
			break;
		default:
			theme = themeList.default;
			break;
	}

	// ohhhhh man this is ugly....could not get mui-input to use theme color
	window.themePrimary = theme.primary;

	// now actually save the theme to redux
	dispatch(setTheme(theme));
};

export function clearTheme() {
	return {
		type: CLEAR_THEME,
	};
}

export const loadVendor = (vendor) => {
	return (dispatch, getState) => {
		dispatch(loadVendorStarted());

		axios
			.post(`/api/vendor/config`, { vendor })
			.then(function (res) {
				window.gtag("event", "Vendor Load on /go URL", {
					event_category: "Dynamic Vendor",
					event_label: res.data.name,
					value: res.data.name,
				});
				document.title = res.data.name;
				dispatch(loadVendorSuccess(res.data));
				const state = getState();
				const config = {
					customVendor: true,
					loaded: true,
					...res.data,
				};
				updateVendorFormValues(state, config, dispatch, vendor);
				setCustomTheme(config, dispatch);
			})
			.catch((err) => {
				dispatch(loadVendorFailure(err.message));
			});
	};
};

const loadVendorSuccess = (config) => ({
	type: LOAD_VENDOR_SUCCESS,
	payload: {
		...config,
	},
});

const loadVendorStarted = () => ({
	type: LOAD_VENDOR_STARTED,
});

const loadVendorFailure = (error) => ({
	type: LOAD_VENDOR_FAILURE,
	payload: {
		error,
	},
});

const updateVendorFormValues = (state, config, dispatch, vendor) => {
	let defaultType = "eq";

	if (config.name === "iFunding" || vendor === 'bankrate' || vendor === 'woocommerce' || vendor === 'spscommerce') {
		defaultType = "wc";
		const wc = { ...config[defaultType], termOptions: ["3", "6", "12"] };
		dispatch(setConfig({ ...config, wc }));
	}
	const configAmount = config[defaultType].amount;
	const configTerms = config[defaultType].selectedTerm;

	if (state.form.application && state.form.application.values) {
		const loanAmount = state.form.application.values.loan_amount;
		const loanTerms = state.form.application.values.loan_terms;
		if (state.form.application.values.loan_type === 'wc') {
			defaultType = 'wc';
		}
		dispatch(change("application", "loan_type", defaultType));

		// if redux has some values but amount or terms are NOT set, go ahead and set from config
		if (!loanAmount) {
			dispatch(change("application", "loan_amount", configAmount));
		}
		if (!loanTerms) {
			dispatch(change("application", "loan_terms", configTerms));
		}

		// if redux values match the default, override them with custom config values
		if (config.customVendor) {
			if (loanAmount === defaultVendor[defaultType].amount) {
				dispatch(change("application", "loan_amount", configAmount));
			}
			if (loanTerms === defaultVendor[defaultType].selectedTerm) {
				dispatch(change("application", "loan_terms", configTerms));
			}
		}
	} else {
		// if state.form.application is empty we definitely need to set
		dispatch(change("application", "loan_amount", configAmount));
		dispatch(change("application", "loan_terms", configTerms));
	}
};

const defaultVendor = {
	id: VENDOR_ID,
	logo: "/img/logo.png",
	loaded: true, // default to true, used for custom vendor loads
	customVendor: false,
	eq: {
		rate: 8,
		min: 5000,
		max: 150000,
		amount: "100000",
		termOptions: [24, 36, 48, 60],
		selectedTerm: "60",
	},
	wc: {
		rate: 10,
		min: 5000,
		max: 250000,
		amount: "150000",
		termOptions: [6, 12, 24],
		selectedTerm: "6",
	},
};

export const loadDefaultVendor = (force = false) => {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.config.customVendor || force) {
			dispatch(loadVendorDefault(defaultVendor));
			updateVendorFormValues(state, defaultVendor, dispatch);
			setCustomTheme(defaultVendor, dispatch);
		}
	};
};

const loadVendorDefault = (config) => ({
	type: LOAD_VENDOR_DEFAULT,
	payload: {
		...config,
	},
});

export function setConfig(config) {
	return {
		type: SET_CONFIG,
		config: config,
	};
}

export function clearConfig() {
	return {
		type: CLEAR_CONFIG,
	};
}

export function setUser(data) {
	if (data.user && data.user.token) {
		return {
			type: SET_USER,
			user: data.user,
		};
	}

	return {
		type: AUTH_FAILED,
	};
}

export function logout() {
	return {
		type: LOGOUT,
	};
}

export const refreshUser = (user) => {
	return (dispatch, getState) => {
		dispatch(refreshUserStarted(user));

		const headers =
			user && user.token ? { Authorization: "Bearer " + user.token } : {};

		axios
			.post(`/api/user/refresh`, user, { headers })
			.then(function (res) {
				dispatch(refreshUserSuccess(res.data));
			})
			.catch((err) => {
				dispatch(refreshUserFailure(err.message));
			});
	};
};

const refreshUserSuccess = (user) => ({
	type: REFRESH_USER_SUCCESS,
	payload: {
		...user,
	},
});

const refreshUserStarted = (user) => ({
	type: REFRESH_USER_STARTED,
	payload: {
		...user, // need to pass existing user object during refresh to void clearing completely
	},
});

const refreshUserFailure = (error) => ({
	type: REFRESH_USER_FAILURE,
	payload: {
		error,
	},
});

export function setTemp(data) {
	return {
		type: SET_TEMP,
		data,
	};
}

export function clearTemp() {
	return {
		type: CLEAR_TEMP,
	};
}

export function setData(data) {
	return {
		type: SET_DATA,
		deals: data.deals,
	};
}

export function clearData() {
	return {
		type: CLEAR_DATA,
	};
}
